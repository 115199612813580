<template>
<form @submit.prevent="VerifyProfile">
  <b-modal v-model="ShowMe" has-modal-card full-screen :can-cancel="false">
    <div class="modal-card">
      <header class="modal-card-head roundless">
          <p class="modal-card-title is-size-5 has-text-white">
            {{ $t('account.completeToContinuePlaying') }}
          </p>
        </header>
      <section class="modal-card-body">
        <user-extra-fields ref="extraFields" />
      </section>

      <footer class="modal-card-foot is-justify-content-flex-end has-background-white-ter">
        <b-button
          v-if="!$store.state.setup.registration.mandatory"
          type="is-primary"
          outlined
          expanded
          @click="Close()"
          :label="$t('system.close')" />
        <!-- <b-button label="Save" type="is-primary" @click="VerifyProfile()" /> -->
        <input type="submit" class="button submit is-fullwidth is-primary" :value="$t('system.save')">
      </footer>

    </div>
  </b-modal>
  </form>
</template>

<script>
import UserExtraFields from "./UserExtraFields.vue";
import axios from "axios";

const ACCOUNTSTATE = Object.freeze({
  inactive: 0,
  login: 1,
  register: 2,
  verify: 3,
});

export default {
  components: {
    UserExtraFields
  },

  data() {
    return {
      email: "",
    };
  },
  computed: {
    extraFields() {
      return this.$refs.extraFields.RetrieveFields();
    },
    ShowMe() {
      return this.$store.state.user.accountState == ACCOUNTSTATE.verify && !this.$store.state.user.declinedVerification;
    },
  },
  methods: {
    VerifyProfile() {
      if (!this.$refs.extraFields.validate()) {
        return
      }
      console.log("sending", this.extraFields)
      axios
        .put(
          process.env.VUE_APP_API_BASE + "/api/users/" + this.$store.state.user.profile.id,
          this.extraFields,
          {
            headers: {
              Authorization: "JWT " + this.$store.state.user.token,
            },
          }
        )
        .then((res) => {
          console.log(res)
          if (res.status == 200) {
            this.$store.commit("user/SetEmail", res.data.doc.email);
            this.$store.commit("user/SetUser", { profile: res.data.doc });
            this.$store.commit("user/SetAccountState", 0);

            this.$buefy.notification.open({
              message: this.$t('notifications.profileVerified'),
              type: "is-primary",
            });

            this.$sfxPlay('positivenotification')
          } else {

            this.$buefy.notification.open({
              message: this.$t('notifications.errorVerifyingProfile'),
              type: "is-danger",
            });

            this.$sfxPlay('negativenotification')
          }
        })
        .catch((err) => {
          console.warn("❗️", err)
          if (err.response.status == 403) {
            // Chequear si este status pueda llegar ser otra cosa
            // Payload por ahora no manda el error especifico
            this.$buefy.notification.open({
              message: this.$t('notifications.emailInUse'),
              type: "is-danger"
            });
            this.$sfxPlay('negativenotification')
          } else {
            this.$buefy.notification.open({
              message: this.$t('notifications.errorVerifyingProfile'),
              type: "is-danger"
            });
            this.$sfxPlay('negativenotification')
          }
        });
    },
    Close() {
      this.$store.commit("user/DeclineVerification")
    }
  },
};
</script>
